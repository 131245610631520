import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = { id: "globalHeader" }
const _hoisted_2 = { class: "user-login-status" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import { h, ref } from "vue";
import { CrownOutlined, HomeOutlined } from "@ant-design/icons-vue";
import { MenuProps } from "ant-design-vue";
import { useRouter } from "vue-router";
import { useLoginUserStore } from "@/store/useLoginUserStore";

export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalHeader',
  setup(__props) {

const loginUserStore = useLoginUserStore();
const router = useRouter();

// 路由跳转事件
const doMenuClick = ({ key }: { key: string }) => {
  router.push({
    path: key,
  });
};

const current = ref<string[]>(["mail"]);
router.afterEach((to, from, failure) => {
  current.value = [to.path];
});
const items = ref<MenuProps["items"]>([
  {
    key: "/",
    icon: () => h(HomeOutlined),
    label: "主页",
    title: "主页",
  },
  {
    key: "/user/login",
    label: "用户登录",
    title: "用户登录",
  },
  {
    key: "/user/register",
    label: "用户注册",
    title: "用户注册",
  },
  {
    key: "/admin/userManage",
    icon: () => h(CrownOutlined),
    label: "用户管理",
    title: "用户管理",
  },
  {
    key: "/roompage/CountdownPage",
    label: "倒计时",
    title: "倒计时",
  },
  {
    key: "/test/testPage",
    label: "测试页",
    title: "测试页",
  },
  {
    key: "other",
    label: h(
      "a",
      { href: "https://mingfancloud.cn", target: "_blank" },
      "明凡编程宝典"
    ),
    title: "明凡编程宝典",
  },
  {
    key: "/others",
    label: h(
      "a",
      { href: "https://download.mingfancloud.cn", target: "_blank" },
      "明凡Download"
    ),
    title: "明凡Download",
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, { wrap: false }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { flex: "150px" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "title-bar" }, [
              _createElementVNode("img", {
                class: "logo",
                src: _imports_0,
                alt: "logo"
              }),
              _createElementVNode("div", { class: "title" }, "明凡云")
            ], -1)
          ])),
          _: 1
        }),
        _createVNode(_component_a_col, { flex: "auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_menu, {
              selectedKeys: current.value,
              "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((current).value = $event)),
              mode: "horizontal",
              items: items.value,
              onClick: doMenuClick
            }, null, 8, ["selectedKeys", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { flex: "80px" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_unref(loginUserStore).loginUser.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(loginUserStore).loginUser.username ?? "无名"), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      href: "/user/login"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" 登录 ")
                      ])),
                      _: 1
                    })
                  ]))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})